/**
 * The Icon component will be able to receive a type property.
 * This type will allow the Icon to use a different className depending on the brand.
 * This will avoid having this mapping into the CSS, which causes lot of undesired duplication
 * Bigger CSS files due to the use of extend or having to create mapping css classes.
 *
 * The exported function will receive a type and will return the class to use depending on the brand
 * Without this, all brands would use the same className, having to create new CSS classes
 */

import { v as bvVar } from 'bv';

const mappings = {
  'feature-comp-title': {
    default: 'is-alternative',
    6: ' ',
    7: 'is-main',
    10: ' ',
    14: ' ',
    17: 'is-main',
    21: ' ',
  },
  'inplay-sport': {
    default: 'is-alternative',
    6: 'is-inverted',
    7: 'is-main',
    10: 'is-inverted',
    14: 'is-inverted',
    17: 'is-main',
    21: ' ',
  },
  'inplay-slider': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'inplay-slider-active': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'upcoming-slider': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'upcoming-slider-active': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'floating-nav': {
    default: ' ',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'list-sport-icon': {
    default: 'is-alternative',
  },
  'user-inbox-remove': {
    default: 'is-alternative',
    6: 'is-inverted',
    7: 'is-main',
    10: 'is-inverted',
    14: 'is-inverted',
    16: 'is-main',
    20: 'is-main',
  },
  'user-inbox-trash': {
    default: 'is-active',
    6: 'is-inverted',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'casino-on-home': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'casino-categories-modal-close': {
    default: 'is-active',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'dots-icon-ac-help-view': {
    default: 'is-main is-solid',
    6: 'is-inverted is-solid',
  },
  'dots-icon': {
    default: 'is-main is-solid',
    6: 'is-inverted is-solid',
    10: 'is-inverted is-solid',
    14: 'is-inverted is-solid',
    21: 'is-inverted is-solid',
  },
  'raf-faq-arrow': {
    default: 'is-arrow-solid-circle-down',
  },
  'offers-info-icon': {
    default: ' ',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'offers-nav-icon': {
    default: ' ',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'leaderboard-piu-events-sport-icon': {
    default: 'is-alternative',
    6: 'is-inverted',
    7: 'is-main',
    10: 'is-inverted',
    14: 'is-inverted',
    17: 'is-main',
    21: ' ',
  },
  'market-view-header-icons': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'in-play-widget-icon': {
    default: ' ',
  },
  'modal-header-close-icon': {
    default: 'is-close is-active',
    16: 'is-cross is-inverted',
    20: 'is-cross is-inverted',
  },
  'modal-header-back-icon': {
    default: 'is-active',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'account-settings-offers-icon': {
    default: 'account-offers-icon ',
  },
  'msg-box-icon': {
    default: ' ',
    16: 'is-big',
    20: 'is-big',
  },
  'quick-deposit-close-icon': {
    default: 'is-close is-active',
    16: 'is-cross is-big is-active',
    20: 'is-cross is-big is-active',
  },
  'game-launch-header-close-icon': {
    default: 'is-close is-active',
    16: 'is-cross is-inverted',
    20: 'is-cross is-inverted',
  },
  'modal-header-dots-icon': {
    default: 'is-solid',
    16: 'is-inverted is-solid',
    20: 'is-inverted is-solid',
  },
  'cashier-payments-trash-icon': {
    default: 'is-alternative',
    16: 'is-main',
    20: 'is-main',
  },
  'search-input-close-button': {
    default: 'is-active',
  },
  'sportsbook-cashout-icons': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'account-history-sport-icon': {
    default: 'is-alternative',
    6: ' ',
    10: ' ',
    14: ' ',
    21: ' ',
  },
  'virtual-scroller-item-icon': {
    default: 'is-main',
    6: ' ',
    10: ' ',
    14: ' ',
    21: ' ',
  },
  'regpath-back-arrow': {
    default: ' ',
    16: 'is-active is-small',
    20: 'is-active is-small',
  },
  'regpath-next-arrow': {
    default: ' ',
    16: 'is-small',
    20: 'is-small',
  },
  'gl-mobile-modal-close-icon': {
    default: 'is-active',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'cashout-card-icon': {
    default: 'is-main',
    6: ' ',
    10: ' ',
    14: ' ',
    21: ' ',
  },
  'time-out-portal-icon': {
    default: 'is-alternative',
  },
  'account-history-header-icon': {
    default: 'is-inverted',
    10: 'is-main',
    14: 'is-main',
  },
  'outcome-search-icon': {
    default: 'is-main',
    6: 'is-inverted',
    10: 'is-inverted',
    14: 'is-inverted',
  },
  'gl-modal-close-icon': {
    default: 'is-active',
    17: ' ',
  },
  'list-modal-dots-close': {
    default: 'is-solid',
    17: 'is-main is-solid',
  },
  'restriction-list-close': {
    default: 'is-active',
    17: 'is-inverted',
  },
  'ctc-steps-icon': {
    default: 'is-inverted',
    17: ' ',
  },
  'sports-home-events-list-icon': {
    default: 'is-alternative',
    6: ' ',
    10: ' ',
    14: ' ',
    21: ' ',
    23: ' ',
    2782537: '',
  },
  'trending-bets-item-header-icon': {
    default: 'is-alternative',
    17: 'is-main ',
  },
  'sow-additional-source-trash-icon': {
    default: 'is-alternative',
    7: 'is-main',
    16: 'is-main',
    20: 'is-main',
  },
  'floating-nav-menu': {
    default: '',
    16: 'is-inverted',
    20: 'is-inverted',
  },
  'bonus-cash': {
    default: '',
    16: 'is-alternative',
    20: 'is-alternative',
  },
};

export default (type) => mappings[type] && (
  mappings[type][bvVar('brandId')] || mappings[type].default
);
