/**
 * The Button component will be able to receive a type property.
 * This type will allow the Button to use a different className depending on the brand.
 * This will avoid having this mapping into the CSS, which causes lot of undesired duplication
 * Bigger CSS files due to the use of extend or having to create mapping css classes.
 *
 * The exported function will receive a type and will return the class to use depending on the brand
 * Without this, all brands would use the same className, having to create new CSS classes
 */

import { v as bvVar } from 'bv';

const mappings = {
  'account-overview-personal-details-edit': {
    default: 'bvs-button is-secondary',
    16: 'bvs-button-alternative is-secondary',
    20: 'bvs-button-alternative is-secondary',
  },
  'login-view-password-change-button': {
    default: 'login-password-change-link',
  },
  'password-reset-button': {
    default: 'is-primary',
    16: 'bvs-button-alternative is-primary',
    20: 'bvs-button-alternative is-primary',
  },
  'settings-view-button': {
    default: 'bvs-button is-secondary',
    16: 'bvs-button is-secondary',
    20: 'bvs-button is-secondary',
  },
  'account-history-menu-button': {
    default: 'bvs-button-tab',
  },
  'user-inbox-msg-cta': {
    default: 'bvs-button is-primary ',
    16: 'bvs-button-alternative is-secondary',
    20: 'bvs-button-alternative is-secondary',
  },
  'regpath-button-next': {
    default: 'bvs-button is-primary',
    16: 'bvs-button-alternative is-primary',
    20: 'bvs-button-alternative is-primary',
  },
  'bb-add-to-betsilp': {
    default: 'is-secondary',
    17: 'is-primary',
  },
  'arrow-left': {
    default: '',
    16: 'is-inverted',
    20: 'is-inverted',
  },
};

export default (type) => mappings[type] && (
  mappings[type][bvVar('brandId')] || mappings[type].default
);
